// Dependencies
import React, { useEffect } from "react"
import { graphql } from "gatsby";
import PropTypes from "prop-types";
// Hooks & Helpers
// ...
// Wrappers
import Layout from "../wrappers/Layout"
// Components
import NewsletterForm from "../components/NewsletterForm"
import RichText from "../components/RichText"
import { useMenuPositioning } from "../components/Menu"

const NewsletterPage = ({ data }) => {

	// Content
	const { title, hasPhoneNumber, listId, content, legalCopy, callToAction } = data.contentfulNewsletterPage

	// UX
	const { reposition } = useMenuPositioning()

	useEffect(() => {
		reposition({ x: 12, y: 0 })
	}, [])

	return (
		<Layout title={title}>
			<section className="grid innerx4 guttersx4">
				<div className={`span-6 start-7`}>
					<div className="mbx2">
						<NewsletterForm isSMS={hasPhoneNumber} listId={listId} callToAction={callToAction} />

						{legalCopy && (
							<div className="outerx4 p3 has-links">
								<RichText content={legalCopy} />
							</div>
						)}
					</div>
					<RichText content={content} />
				</div>
			</section>
    </Layout>
	)
}

NewsletterPage.propTypes = {
  data: PropTypes.object.isRequired
}

export default NewsletterPage

export const NewsletterPageQuery = graphql`
  query NewsletterPageQuery($slug: String!) {
    contentfulNewsletterPage(slug: {eq: $slug}) {
      title
			listId
			hasPhoneNumber
			callToAction
			legalCopy {
				raw
				references {
					... on ContentfulPage {
						__typename
            contentful_id
						slug
					}
				}
			}
			content {
        raw
				references {
					... on ContentfulAsset {
            __typename
            contentful_id
						gatsbyImageData(
							layout: FULL_WIDTH
						)
          }
				}
      }
    }
  }
`
